type CryptoData = {
  [key: string]: {
    minPriceIncrement: number;
    minSizeIncrement: number;
    minimumSize: number;
    maximumSize: number;
  };
};

const useCryptoMinMax = (): CryptoData => {
  const cryptoData: CryptoData = {
    Aave: {
      minPriceIncrement: 0.01,
      minSizeIncrement: 0.0001,
      minimumSize: 0.005,
      maximumSize: 3000,
    },
    Algorand: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.0001,
      minimumSize: 1.0,
      maximumSize: 500000,
    },
    ApeCoin: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 10000,
    },
    Aptos: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.001,
      minimumSize: 0.001,
      maximumSize: 10000,
    },
    Arbitrum: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.0001,
      maximumSize: 20000,
    },
    Arweave: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.001,
      minimumSize: 0.001,
      maximumSize: 4000,
    },
    Avalanche: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 4000,
    },
    Balancer: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.0001,
      maximumSize: 20000,
    },
    "Basic Attention": {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.001,
      minimumSize: 0.001,
      maximumSize: 200000,
    },
    Bitcoin: {
      minPriceIncrement: 1.0,
      minSizeIncrement: 0.000001,
      minimumSize: 0.0001,
      maximumSize: 50,
    },
    "Bitcoin Cash": {
      minPriceIncrement: 0.01,
      minSizeIncrement: 0.0001,
      minimumSize: 0.01,
      maximumSize: 300,
    },
    Blur: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.0001,
      maximumSize: 100000,
    },
    Cardano: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.0001,
      minimumSize: 1.0,
      maximumSize: 4000000,
    },
    Chainlink: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 60000,
    },
    Compound: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.01,
      maximumSize: 6083,
    },
    Cosmos: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.005,
      maximumSize: 8500,
    },
    "Curve DAO": {
      minPriceIncrement: 0.0000001,
      minSizeIncrement: 0.001,
      minimumSize: 0.001,
      maximumSize: 200000,
    },
    Dai: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.01,
      maximumSize: 1000000,
    },
    Decentraland: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 50000,
    },
    Dogecoin: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.0001,
      minimumSize: 1.0,
      maximumSize: 1000000,
    },
    EOS: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.0001,
      minimumSize: 1,
      maximumSize: 1000000,
    },
    Ethereum: {
      minPriceIncrement: 0.01,
      minSizeIncrement: 0.0001,
      minimumSize: 0.01,
      maximumSize: 1400,
    },
    "Ethereum Classic": {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 160000,
    },
    Filecoin: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 30000,
    },
    Flow: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.001,
      minimumSize: 0.1,
      maximumSize: 100000,
    },
    Fantom: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 100000,
    },
    GALA: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 1000000,
    },
    Hedera: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.01,
      minimumSize: 0.01,
      maximumSize: 300000,
    },
    "Internet Computer": {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.0001,
      maximumSize: 50000,
    },
    Immutable: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.001,
      maximumSize: 20000,
    },
    Kusama: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.01,
      maximumSize: 1600,
    },
    Litecoin: {
      minPriceIncrement: 0.01,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 14000,
    },
    Maker: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.000001,
      minimumSize: 0.000001,
      maximumSize: 40,
    },
    Moonbeam: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 100000,
    },
    "NEAR Protocol": {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.005,
      maximumSize: 35000,
    },
    Polkadot: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.5,
      maximumSize: 100000,
    },
    Polygon: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.0001,
      minimumSize: 1.0,
      maximumSize: 4000000,
    },
    Quant: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.00001,
      minimumSize: 0.00001,
      maximumSize: 200,
    },
    Solana: {
      minPriceIncrement: 0.01,
      minSizeIncrement: 0.0001,
      minimumSize: 0.01,
      maximumSize: 4000,
    },
    Stellar: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.0001,
      minimumSize: 100.0,
      maximumSize: 4000000,
    },
    Sui: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.000001,
      maximumSize: 40000,
    },
    "Synthetix Network": {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.001,
      minimumSize: 0.001,
      maximumSize: 20000,
    },
    Tether: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.01,
      maximumSize: 600000,
    },
    Tezos: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.0001,
      minimumSize: 1.0,
      maximumSize: 80000,
    },
    "The Graph": {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 300000,
    },
    "The Sandbox": {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 50000,
    },
    TRON: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.005,
      maximumSize: 1600000,
    },
    Uniswap: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 25000,
    },
    USDC: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.01,
      maximumSize: 600000,
    },
    "XDC Network": {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.0001,
      minimumSize: 0.1,
      maximumSize: 140000,
    },
    XRP: {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.001,
      minimumSize: 0.001,
      maximumSize: 200000,
    },
    "Yearn Finance": {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.000001,
      minimumSize: 0.000001,
      maximumSize: 11,
    },
    "1inch": {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 11,
      maximumSize: 20700,
    },
    "Alchemy Pay": {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00000001,
      minimumSize: 250,
      maximumSize: 315500,
    },
    Aevo: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00001,
      minimumSize: 5,
      maximumSize: 10000,
    },
    "Akash Network": {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 1.2,
      maximumSize: 2200,
    },
    "Ankr Network": {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00000001,
      minimumSize: 120,
      maximumSize: 220500,
    },
    API3: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 1,
      maximumSize: 2900,
    },
    Astar: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 25,
      maximumSize: 103900,
    },
    "Star Atlas": {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00000001,
      minimumSize: 1000,
      maximumSize: 2572700,
    },
    Audius: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 25,
      maximumSize: 51400,
    },
    "Axie Infinity": {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 0.5,
      maximumSize: 1200,
    },
    Beam: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00001,
      minimumSize: 100,
      maximumSize: 371600,
    },
    BarnBridge: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 1.5,
      maximumSize: 3000,
    },
    Bonk: {
      minPriceIncrement: 0.000000001,
      minSizeIncrement: 0.00001,
      minimumSize: 150000,
      maximumSize: 300030000,
    },
    Centrifuge: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 8,
      maximumSize: 14800,
    },
    Chiliz: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 50,
      maximumSize: 69900,
    },
    dYdX: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 2,
      maximumSize: 4600,
    },
    MultiversX: {
      minPriceIncrement: 0.01,
      minSizeIncrement: 0.00000001,
      minimumSize: 0.1,
      maximumSize: 300,
    },
    Ethena: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00001,
      minimumSize: 5,
      maximumSize: 10200,
    },
    "Enjin Coin": {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 15,
      maximumSize: 33600,
    },
    "Ethereum Name Service": {
      minPriceIncrement: 0.01,
      minSizeIncrement: 0.00000001,
      minimumSize: 0.3,
      maximumSize: 400,
    },
    "Ether.fi": {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00001,
      minimumSize: 0.8,
      maximumSize: 2100,
    },
    "Fetch.ai": {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 2.5,
      maximumSize: 4800,
    },
    Bonfida: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 20,
      maximumSize: 25200,
    },
    Flare: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00000001,
      minimumSize: 150,
      maximumSize: 317100,
    },
    "Frax Share": {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 0.5,
      maximumSize: 2100,
    },
    GMX: {
      minPriceIncrement: 0.01,
      minSizeIncrement: 0.00000001,
      minimumSize: 0.13,
      maximumSize: 200,
    },
    Gnosis: {
      minPriceIncrement: 0.01,
      minSizeIncrement: 0.00000001,
      minimumSize: 0.02,
      maximumSize: 29.54,
    },
    Hashflow: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 15,
      maximumSize: 31000,
    },
    Helium: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00001,
      minimumSize: 1,
      maximumSize: 2500,
    },
    Injective: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 0.15,
      maximumSize: 400,
    },
    JasmyCoin: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00000001,
      minimumSize: 130,
      maximumSize: 256600,
    },
    Jito: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00001,
      minimumSize: 2,
      maximumSize: 2700,
    },
    Jupiter: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00001,
      minimumSize: 5,
      maximumSize: 8800,
    },
    Kava: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 7,
      maximumSize: 14100,
    },
    "Lido DAO": {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 1.5,
      maximumSize: 4500,
    },
    LimeWire: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00001,
      minimumSize: 11,
      maximumSize: 22300,
    },
    Livepeer: {
      minPriceIncrement: 0.01,
      minSizeIncrement: 0.00000001,
      minimumSize: 0.3,
      maximumSize: 400,
    },
    "Mina Protocol": {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 4,
      maximumSize: 11600,
    },
    Enzyme: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 0.3,
      maximumSize: 400,
    },
    Moonriver: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 0.2,
      maximumSize: 600,
    },
    Maple: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00001,
      minimumSize: 0.4,
      maximumSize: 600,
    },
    Nosana: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00001,
      minimumSize: 1,
      maximumSize: 2000,
    },
    "Ocean Protocol": {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 6,
      maximumSize: 11200,
    },
    "OMG Network": {
      minPriceIncrement: 0.000001,
      minSizeIncrement: 0.00000001,
      minimumSize: 7,
      maximumSize: 22800,
    },
    Ondo: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00001,
      minimumSize: 3.5,
      maximumSize: 7100,
    },
    Optimism: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00001,
      minimumSize: 1.5,
      maximumSize: 4000,
    },
    Orca: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 1,
      maximumSize: 4000,
    },
    "PAX Gold": {
      minPriceIncrement: 0.01,
      minSizeIncrement: 0.00000001,
      minimumSize: 0.003,
      maximumSize: 4.23,
    },
    Pepe: {
      minPriceIncrement: 0.000000001,
      minSizeIncrement: 0.00001,
      minimumSize: 350000,
      maximumSize: 685401000,
    },
    "Perpetual Protocol": {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 4,
      maximumSize: 8400,
    },
    "Pyth Network": {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00001,
      minimumSize: 9,
      maximumSize: 21200,
    },
    RARI: {
      minPriceIncrement: 0.01,
      minSizeIncrement: 0.00000001,
      minimumSize: 1.8,
      maximumSize: 3600,
    },
    Raydium: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 3,
      maximumSize: 5400,
    },
    Ren: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 90,
      maximumSize: 125300,
    },
    Render: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 0.5,
      maximumSize: 900,
    },
    "Rocket Pool": {
      minPriceIncrement: 0.01,
      minSizeIncrement: 0.00000001,
      minimumSize: 0.15,
      maximumSize: 400,
    },
    THORChain: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 1,
      maximumSize: 1600,
    },
    Siacoin: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00000001,
      minimumSize: 650,
      maximumSize: 1524600,
    },
    Sei: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00001,
      minimumSize: 7,
      maximumSize: 19200,
    },
    "Shiba Inu": {
      minPriceIncrement: 0.00000001,
      minSizeIncrement: 0.00001,
      minimumSize: 200000,
      maximumSize: 389408100,
    },
    Starknet: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00001,
      minimumSize: 1,
      maximumSize: 7700,
    },
    Stacks: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 2,
      maximumSize: 4400,
    },
    SuperVerse: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 5,
      maximumSize: 9300,
    },
    Sushi: {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 4,
      maximumSize: 8400,
    },
    Celestia: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00001,
      minimumSize: 0.3,
      maximumSize: 1000,
    },
    Tensor: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00001,
      minimumSize: 4,
      maximumSize: 7600,
    },
    TrueFi: {
      minPriceIncrement: 0.00001,
      minSizeIncrement: 0.00000001,
      minimumSize: 25,
      maximumSize: 49300,
    },
    Wormhole: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00001,
      minimumSize: 2,
      maximumSize: 16100,
    },
    Waves: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00000001,
      minimumSize: 2,
      maximumSize: 7000,
    },
    Wen: {
      minPriceIncrement: 0.00000001,
      minSizeIncrement: 0.00000001,
      minimumSize: 25000,
      maximumSize: 49043600,
    },
    dogwifhat: {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00001,
      minimumSize: 1.5,
      maximumSize: 3000,
    },
    "Zeus Network": {
      minPriceIncrement: 0.0001,
      minSizeIncrement: 0.00001,
      minimumSize: 11,
      maximumSize: 20400,
    },
    "0x Protocol": {
      minPriceIncrement: 0.001,
      minSizeIncrement: 0.00000001,
      minimumSize: 15,
      maximumSize: 18800,
    },
  };

  return cryptoData;
};

export default useCryptoMinMax;
