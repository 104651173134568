export type SecurityCode =
  | "btc"
  | "eth"
  | "xrp"
  | "ltc"
  | "usdt"
  | "eos"
  | "ada"
  | "avax"
  | "comp"
  | "dai"
  | "dot"
  | "ksm"
  | "link"
  | "matic"
  | "sol"
  | "uni"
  | "usdc"
  | "xlm"
  | "atom"
  | "near"
  | "trx"
  | "aave"
  | "xtz"
  | "bch"
  | "etc"
  | "doge"
  | "glmr"
  | "xdc"
  | "algo"
  | "ape"
  | "ftm"
  | "gala"
  | "grt"
  | "mana"
  | "sand";

export type SecurityCodeSek =
  | "btcsek"
  | "ethsek"
  | "xrpsek"
  | "ltcsek"
  | "usdtsek"
  | "eossek"
  | "adasek"
  | "avaxsek"
  | "compsek"
  | "daisek"
  | "dotsek"
  | "ksmsek"
  | "linksek"
  | "maticsek"
  | "solsek"
  | "unisek"
  | "usdcsek"
  | "xlmsek"
  | "atomsek"
  | "nearsek"
  | "trxsek"
  | "aavesek"
  | "xtzsek"
  | "bchsek"
  | "etcsek"
  | "dogesek"
  | "glmrsek"
  | "xdcsek"
  | "algosek"
  | "apesek"
  | "ftmsek"
  | "galasek"
  | "grtsek"
  | "manasek"
  | "sandsek";

export const VALID_SECURITY_CODES: SecurityCode[] = [
  "btc",
  "eth",
  "xrp",
  "ltc",
  "usdt",
  "eos",
  "ada",
  "avax",
  "comp",
  "dai",
  "dot",
  "ksm",
  "link",
  "matic",
  "sol",
  "uni",
  "usdc",
  "xlm",
  "atom",
  "near",
  "trx",
  "aave",
  "xtz",
  "bch",
  "etc",
  "doge",
  "glmr",
  "xdc",
  "algo",
  "ape",
  "ftm",
  "gala",
  "grt",
  "mana",
  "sand",
];

export const VALID_SECURITY_CODES_SEK: SecurityCodeSek[] = [
  "btcsek",
  "ethsek",
  "xrpsek",
  "ltcsek",
  "usdtsek",
  "eossek",
  "adasek",
  "avaxsek",
  "compsek",
  "daisek",
  "dotsek",
  "ksmsek",
  "linksek",
  "maticsek",
  "solsek",
  "unisek",
  "usdcsek",
  "xlmsek",
  "atomsek",
  "nearsek",
  "trxsek",
  "aavesek",
  "xtzsek",
  "bchsek",
  "etcsek",
  "dogesek",
  "glmrsek",
  "xdcsek",
  "algosek",
  "apesek",
  "ftmsek",
  "galasek",
  "grtsek",
  "manasek",
  "sandsek",
];

// FA security codes
export type SecurityCodeWithoutTransferSupport =
  | "apt"
  | "arb"
  | "ar"
  | "fil"
  | "flow"
  | "icp"
  | "sui"
  | "bal"
  | "bat"
  | "blur"
  | "crv"
  | "hbar"
  | "imx"
  | "mkr"
  | "qnt"
  | "snx"
  | "yfi"
  | "1inch"
  | "ach"
  | "aevo"
  | "akt"
  | "ankr"
  | "api3"
  | "astr"
  | "atlas"
  | "audio"
  | "axs"
  | "beam"
  | "bond"
  | "bonk"
  | "cfg"
  | "chz"
  | "dydx"
  | "egld"
  | "ena"
  | "enj"
  | "ens"
  | "ethfi"
  | "fet"
  | "fida"
  | "flr"
  | "fxs"
  | "gmx"
  | "gno"
  | "hft"
  | "hnt"
  | "inj"
  | "jasmy"
  | "jto"
  | "jup"
  | "kava"
  | "ldo"
  | "lmwr"
  | "lpt"
  | "mina"
  | "mln"
  | "movr"
  | "mpl"
  | "nos"
  | "ocean"
  | "omg"
  | "ondo"
  | "op"
  | "orca"
  | "paxg"
  | "pepe"
  | "perp"
  | "pyth"
  | "rari"
  | "ray"
  | "ren"
  | "rndr"
  | "rpl"
  | "rune"
  | "sc"
  | "sei"
  | "shib"
  | "strk"
  | "stx"
  | "super"
  | "sushi"
  | "tia"
  | "tnsr"
  | "tru"
  | "w"
  | "waves"
  | "wen"
  | "wif"
  | "zeus"
  | "zrx";

export const SECURITY_CODES_WITHOUT_TRANSFER_SUPPORT: SecurityCodeWithoutTransferSupport[] =
  [
    "apt",
    "arb",
    "ar",
    "fil",
    "flow",
    "icp",
    "sui",
    "bal",
    "bat",
    "blur",
    "crv",
    "hbar",
    "imx",
    "mkr",
    "qnt",
    "snx",
    "yfi",
    "1inch",
    "ach",
    "aevo",
    "akt",
    "ankr",
    "api3",
    "astr",
    "atlas",
    "audio",
    "axs",
    "beam",
    "bond",
    "bonk",
    "cfg",
    "chz",
    "dydx",
    "egld",
    "ena",
    "enj",
    "ens",
    "ethfi",
    "fet",
    "fida",
    "flr",
    "fxs",
    "gmx",
    "gno",
    "hft",
    "hnt",
    "inj",
    "jasmy",
    "jto",
    "jup",
    "kava",
    "ldo",
    "lmwr",
    "lpt",
    "mina",
    "mln",
    "movr",
    "mpl",
    "nos",
    "ocean",
    "omg",
    "ondo",
    "op",
    "orca",
    "paxg",
    "pepe",
    "perp",
    "pyth",
    "rari",
    "ray",
    "ren",
    "rndr",
    "rpl",
    "rune",
    "sc",
    "sei",
    "shib",
    "strk",
    "stx",
    "super",
    "sushi",
    "tia",
    "tnsr",
    "tru",
    "w",
    "waves",
    "wen",
    "wif",
    "zeus",
    "zrx",
  ];

export type AssetId =
  | "BTC"
  | "ETH"
  | "XRP"
  | "LTC"
  | "USDT_ERC20"
  | "EOS"
  | "ADA"
  | "AVAX"
  | "COMP"
  | "DAI"
  | "DOT"
  | "KSM"
  | "LINK"
  | "MATIC"
  | "SOL"
  | "UNI"
  | "USDC"
  | "XLM"
  | "ATOM_COS"
  | "NEAR"
  | "TRX"
  | "AAVE"
  | "XTZ"
  | "BCH"
  | "ETC"
  | "DOGE"
  | "GLMR_GLMR"
  | "XDC"
  | "ALGO"
  | "APE_ETH"
  | "FTM_FANTOM"
  | "GALA2"
  | "GRT"
  | "MANA"
  | "SAND";

export const VALID_ASSET_IDS: AssetId[] = [
  "BTC",
  "ETH",
  "XRP",
  "LTC",
  "USDT_ERC20",
  "EOS",
  "ADA",
  "AVAX",
  "COMP",
  "DAI",
  "DOT",
  "KSM",
  "LINK",
  "MATIC",
  "SOL",
  "UNI",
  "USDC",
  "XLM",
  "ATOM_COS",
  "NEAR",
  "TRX",
  "AAVE",
  "XTZ",
  "BCH",
  "ETC",
  "DOGE",
  "GLMR_GLMR",
  "XDC",
  "ALGO",
  "APE_ETH",
  "FTM_FANTOM",
  "GALA2",
  "GRT",
  "MANA",
  "SAND",
];

export type CurrencyCode = "EUR" | "SEK";

export const VALID_CURRENCY_CODES: CurrencyCode[] = ["EUR", "SEK"];

export const isSecurityCode = (
  securityCode: string
): securityCode is SecurityCode => {
  return VALID_SECURITY_CODES.includes(securityCode as SecurityCode);
};

export const isAssetId = (assetId: string): assetId is AssetId => {
  return VALID_ASSET_IDS.includes(assetId as AssetId);
};

export const isFiatCurrencyCode = (
  codeToTest: string
): codeToTest is CurrencyCode => {
  return codeToTest === "EUR" || codeToTest === "SEK";
};

export const ASSET_ID_TO_SECURITY_CODE: Record<AssetId, SecurityCode> = {
  BTC: "btc",
  ETH: "eth",
  XRP: "xrp",
  LTC: "ltc",
  USDT_ERC20: "usdt",
  EOS: "eos",
  ADA: "ada",
  AVAX: "avax",
  COMP: "comp",
  DAI: "dai",
  DOT: "dot",
  KSM: "ksm",
  LINK: "link",
  MATIC: "matic",
  SOL: "sol",
  UNI: "uni",
  USDC: "usdc",
  XLM: "xlm",
  ATOM_COS: "atom",
  NEAR: "near",
  TRX: "trx",
  AAVE: "aave",
  XTZ: "xtz",
  BCH: "bch",
  ETC: "etc",
  DOGE: "doge",
  GLMR_GLMR: "glmr",
  XDC: "xdc",
  ALGO: "algo",
  APE_ETH: "ape",
  FTM_FANTOM: "ftm",
  GALA2: "gala",
  GRT: "grt",
  MANA: "mana",
  SAND: "sand",
};

const TEST_ASSET_IDS_TO_SECURITY_CODES: Record<string, SecurityCode> = {
  ETH_TEST5: "eth",
  XRP_TEST: "xrp",
  LTC_TEST: "ltc",
  USDC_TEST3: "usdc",
  XLM_TEST: "xlm",
};

export const COINGECKO_IDS: Record<AssetId, string> = {
  BTC: "bitcoin",
  ETH: "ethereum",
  XRP: "ripple",
  LTC: "litecoin",
  USDT_ERC20: "tether",
  EOS: "eos",
  ADA: "cardano",
  AVAX: "avalanche-2",
  COMP: "compound-governance-token",
  DAI: "dai",
  DOT: "polkadot",
  KSM: "kusama",
  LINK: "chainlink",
  MATIC: "matic-network",
  SOL: "solana",
  UNI: "uniswap",
  USDC: "usd-coin",
  XLM: "stellar",
  ATOM_COS: "cosmos",
  NEAR: "near",
  TRX: "tron",
  AAVE: "aave",
  XTZ: "tezos",
  BCH: "bitcoin-cash",
  ETC: "ethereum-classic",
  DOGE: "dogecoin",
  GLMR_GLMR: "moonbeam",
  XDC: "xdce-crowd-sale",
  ALGO: "algorand",
  APE_ETH: "apecoin",
  FTM_FANTOM: "fantom",
  GALA2: "gala",
  GRT: "the-graph",
  MANA: "decentraland",
  SAND: "the-sandbox",
};

// not in use
export const SECURITY_CODE_TO_COINGECKO_ID: Record<
  SecurityCode | SecurityCodeWithoutTransferSupport,
  string
> = {
  btc: "bitcoin",
  eth: "ethereum",
  xrp: "ripple",
  ltc: "litecoin",
  usdt: "tether",
  eos: "eos",
  ada: "cardano",
  avax: "avalanche-2",
  comp: "compound-governance-token",
  dai: "dai",
  dot: "polkadot",
  ksm: "kusama",
  link: "chainlink",
  matic: "matic-network",
  sol: "solana",
  uni: "uniswap",
  usdc: "usd-coin",
  xlm: "stellar",
  atom: "cosmos",
  near: "near",
  trx: "tron",
  aave: "aave",
  xtz: "tezos",
  bch: "bitcoin-cash",
  etc: "ethereum-classic",
  doge: "dogecoin",
  glmr: "moonbeam",
  xdc: "xdce-crowd-sale",
  algo: "algorand",
  ape: "apecoin",
  ftm: "fantom",
  gala: "gala",
  grt: "the-graph",
  mana: "decentraland",
  sand: "the-sandbox",
  apt: "aptos",
  ar: "arweave",
  arb: "arbitrum",
  fil: "filecoin",
  flow: "flow",
  icp: "internet-computer",
  sui: "sui",
  bal: "balancer",
  bat: "basic-attention-token",
  blur: "blur",
  crv: "curve-dao-token",
  hbar: "hedera-hashgraph",
  imx: "immutable-x",
  mkr: "maker",
  qnt: "quant-network",
  snx: "havven",
  yfi: "yearn-finance",
  "1inch": "1inch",
  ach: "alchemy-pay",
  aevo: "aevo-exchange",
  akt: "akash-network",
  ankr: "ankr",
  api3: "api3",
  astr: "astar",
  atlas: "star-atlas",
  audio: "audius",
  axs: "axie-infinity",
  beam: "beam-2",
  bond: "barnbridge",
  bonk: "bonk",
  cfg: "centrifuge",
  chz: "chiliz",
  dydx: "dydx-chain",
  egld: "elrond-erd-2",
  ena: "ethena",
  enj: "enjincoin",
  ens: "ethereum-name-service",
  ethfi: "ether-fi",
  fet: "fetch-ai",
  fida: "bonfida",
  flr: "flare-networks",
  fxs: "frax-share",
  gmx: "gmx",
  gno: "gnosis",
  hft: "hashflow",
  hnt: "helium",
  inj: "injective-protocol",
  jasmy: "jasmycoin",
  jto: "jito-governance-token",
  jup: "jupiter-exchange-solana",
  kava: "kava",
  ldo: "lido-dao",
  lmwr: "limewire-token",
  lpt: "livepeer",
  mina: "mina-protocol",
  mln: "melon",
  movr: "moonriver",
  mpl: "maple",
  nos: "nosana",
  ocean: "ocean-protocol",
  omg: "omisego",
  ondo: "ondo-finance",
  op: "optimism",
  orca: "orca",
  paxg: "pax-gold",
  pepe: "pepe",
  perp: "perpetual-protocol",
  pyth: "pyth-network",
  rari: "rarible",
  ray: "raydium",
  ren: "ren",
  rndr: "render-token",
  rpl: "rocket-pool",
  rune: "thorchain",
  sc: "siacoin",
  sei: "sei-network",
  shib: "shiba-inu",
  strk: "starknet",
  stx: "blockstack",
  super: "superfarm",
  sushi: "sushi",
  tia: "celestia",
  tnsr: "tensor",
  tru: "truefi",
  w: "wormhole",
  waves: "waves",
  wen: "wen-solana",
  wif: "dogwifcoin",
  zeus: "zeus-network",
  zrx: "0x",
};

export const SECURITY_CODE_TO_ASSET_ID: Record<SecurityCode, AssetId> = {
  btc: "BTC",
  eth: "ETH",
  xrp: "XRP",
  ltc: "LTC",
  usdt: "USDT_ERC20",
  eos: "EOS",
  ada: "ADA",
  avax: "AVAX",
  comp: "COMP",
  dai: "DAI",
  dot: "DOT",
  ksm: "KSM",
  link: "LINK",
  matic: "MATIC",
  sol: "SOL",
  uni: "UNI",
  usdc: "USDC",
  xlm: "XLM",
  atom: "ATOM_COS",
  near: "NEAR",
  trx: "TRX",
  aave: "AAVE",
  xtz: "XTZ",
  bch: "BCH",
  etc: "ETC",
  doge: "DOGE",
  glmr: "GLMR_GLMR",
  xdc: "XDC",
  algo: "ALGO",
  ape: "APE_ETH",
  ftm: "FTM_FANTOM",
  gala: "GALA2",
  grt: "GRT",
  mana: "MANA",
  sand: "SAND",
};

export const ASSET_NAME_TO_CHAIN_NAME: Record<string, string> = {
  Aave: "Ethereum",
  Cardano: "Cardano",
  Avalanche: "C-Chain",
  Chainlink: "Ethereum",
  Compound: "Ethereum",
  Dai: "Ethereum",
  Polygon: "Ethereum",
  Tether: "Ethereum",
  Uniswap: "Ethereum",
  USDC: "Ethereum",
};
// FA name to FA security code
export const NAME_TO_SECURITY_CODE: Record<
  string,
  SecurityCode | SecurityCodeWithoutTransferSupport
> = {
  Bitcoin: "btc",
  Ethereum: "eth",
  XRP: "xrp",
  Litecoin: "ltc",
  Tether: "usdt",
  EOS: "eos",
  Cardano: "ada",
  Avalanche: "avax",
  Compound: "comp",
  Dai: "dai",
  Polkadot: "dot",
  Kusama: "ksm",
  Chainlink: "link",
  Polygon: "matic",
  Solana: "sol",
  Uniswap: "uni",
  USDC: "usdc",
  Stellar: "xlm",
  Cosmos: "atom",
  "NEAR Protocol": "near",
  TRON: "trx",
  Aave: "aave",
  Tezos: "xtz",
  "Bitcoin Cash": "bch",
  "Ethereum Classic": "etc",
  Dogecoin: "doge",
  Moonbeam: "glmr",
  "XDC Network": "xdc",
  Algorand: "algo",
  ApeCoin: "ape",
  FANTOM: "ftm",
  GALA: "gala",
  "The Graph": "grt",
  Decentraland: "mana",
  "The Sandbox": "sand",
  Aptos: "apt",
  Arweave: "ar",
  Arbitrum: "arb",
  Balancer: "bal",
  "Basic Attention": "bat",
  Blur: "blur",
  "Curve DAO": "crv",
  Filecoin: "fil",
  Flow: "flow",
  Hedera: "hbar",
  "Internet Computer": "icp",
  Immutable: "imx",
  Maker: "mkr",
  Quant: "qnt",
  "Synthetix Network": "snx",
  Sui: "sui",
  "Yearn Finance": "yfi",
  "1inch": "1inch",
  "Alchemy Pay": "ach",
  Aevo: "aevo",
  "Akash Network": "akt",
  "Ankr Network": "ankr",
  API3: "api3",
  Astar: "astr",
  "Star Atlas": "atlas",
  Audius: "audio",
  "Axie Infinity": "axs",
  Beam: "beam",
  BarnBridge: "bond",
  Bonk: "bonk",
  Centrifuge: "cfg",
  Chiliz: "chz",
  dYdX: "dydx",
  MultiversX: "egld",
  Ethena: "ena",
  "Enjin Coin": "enj",
  "Ethereum Name Service": "ens",
  "Ether.fi": "ethfi",
  "Fetch.ai": "fet",
  Bonfida: "fida",
  Flare: "flr",
  "Frax Share": "fxs",
  GMX: "gmx",
  Gnosis: "gno",
  Hashflow: "hft",
  Helium: "hnt",
  Injective: "inj",
  JasmyCoin: "jasmy",
  Jito: "jto",
  Jupiter: "jup",
  Kava: "kava",
  "Lido DAO": "ldo",
  LimeWire: "lmwr",
  Livepeer: "lpt",
  "Mina Protocol": "mina",
  Enzyme: "mln",
  Moonriver: "movr",
  Maple: "mpl",
  Nosana: "nos",
  "Ocean Protocol": "ocean",
  "OMG Network": "omg",
  Ondo: "ondo",
  Optimism: "op",
  Orca: "orca",
  "PAX Gold": "paxg",
  Pepe: "pepe",
  "Perpetual Protocol": "perp",
  "Pyth Network": "pyth",
  RARI: "rari",
  Raydium: "ray",
  Ren: "ren",
  Render: "rndr",
  "Rocket Pool": "rpl",
  THORChain: "rune",
  Siacoin: "sc",
  Sei: "sei",
  "Shiba Inu": "shib",
  Starknet: "strk",
  Stacks: "stx",
  SuperVerse: "super",
  Sushi: "sushi",
  Celestia: "tia",
  Tensor: "tnsr",
  TrueFi: "tru",
  Wormhole: "w",
  Waves: "waves",
  Wen: "wen",
  dogwifhat: "wif",
  "Zeus Network": "zeus",
  "0x Protocol": "zrx",
};

export const SECURITY_CODE_TO_COINBASE_ID: Record<
  | SecurityCode
  | "apt"
  | "arb"
  | "ar"
  | "fil"
  | "flow"
  | "icp"
  | "sui"
  | "bal"
  | "bat"
  | "blur"
  | "crv"
  | "hbar"
  | "imx"
  | "mkr"
  | "qnt"
  | "snx"
  | "yfi"
  | "1inch"
  | "ach"
  | "aevo"
  | "akt"
  | "ankr"
  | "api3"
  | "astr"
  | "atlas"
  | "audio"
  | "axs"
  | "beam"
  | "bond"
  | "bonk"
  | "cfg"
  | "chz"
  | "dydx"
  | "egld"
  | "ena"
  | "enj"
  | "ens"
  | "ethfi"
  | "fet"
  | "fida"
  | "flr"
  | "fxs"
  | "gmx"
  | "gno"
  | "hft"
  | "hnt"
  | "inj"
  | "jasmy"
  | "jto"
  | "jup"
  | "kava"
  | "ldo"
  | "lmwr"
  | "lpt"
  | "mina"
  | "mln"
  | "movr"
  | "mpl"
  | "nos"
  | "ocean"
  | "omg"
  | "ondo"
  | "op"
  | "orca"
  | "paxg"
  | "pepe"
  | "perp"
  | "pyth"
  | "rari"
  | "ray"
  | "ren"
  | "rndr"
  | "rpl"
  | "rune"
  | "sc"
  | "sei"
  | "shib"
  | "strk"
  | "stx"
  | "super"
  | "sushi"
  | "tia"
  | "tnsr"
  | "tru"
  | "w"
  | "waves"
  | "wen"
  | "wif"
  | "zeus"
  | "zrx",
  string
> = {
  aave: "COINBASE:AAVEEUR",
  avax: "COINBASE:AVAXEUR",
  btc: "COINBASE:BTCEUR",
  ada: "COINBASE:ADAEUR",
  link: "COINBASE:LINKEUR",
  comp: "KRAKEN:COMPEUR",
  atom: "COINBASE:ATOMEUR",
  dai: "KRAKEN:DAIEUR",
  eos: "COINBASE:EOSEUR",
  eth: "COINBASE:ETHEUR",
  ksm: "KRAKEN:KSMEUR",
  ltc: "COINBASE:LTCEUR",
  near: "KRAKEN:NEAREUR",
  dot: "COINBASE:DOTEUR",
  matic: "COINBASE:MATICEUR",
  sol: "COINBASE:SOLEUR",
  xlm: "COINBASE:XLMEUR",
  usdt: "COINBASE:USDTEUR",
  xtz: "COINBASE:XTZEUR",
  trx: "KRAKEN:TRXEUR",
  uni: "COINBASE:UNIEUR",
  usdc: "COINBASE:USDCEUR",
  xrp: "KRAKEN:XRPEUR",
  etc: "COINBASE:ETCEUR",
  bch: "COINBASE:BCHEUR",
  doge: "COINBASE:DOGEEUR",
  glmr: "KRAKEN:GLMREUR",
  xdc: "BITTREX:XDCEUR",
  algo: "COINBASE:ALGOEUR",
  ape: "COINBASE:APEEUR",
  ftm: "KRAKEN:FTMEUR",
  gala: "KRAKEN:GALAEUR",
  grt: "COINBASE:GRTEUR",
  mana: "COINBASE:MANAEUR",
  sand: "KRAKEN:SANDEUR",
  apt: "KRAKEN:APTEUR",
  ar: "BITTREX:ARBTC",
  arb: "KRAKEN:ARBEUR",
  fil: "COINBASE:FILEUR",
  flow: "KRAKEN:FLOWEUR",
  icp: "COINBASE:ICPEUR",
  sui: "KRAKEN:SUIEUR",
  bal: "KRAKEN:BALEUR",
  bat: "COINBASE:BATEUR",
  blur: "KRAKEN:BLUREUR",
  crv: "COINBASE:CRVEUR",
  hbar: "BITSTAMP:HBAREUR",
  imx: "KRAKEN:IMXEUR",
  mkr: "KRAKEN:MKREUR",
  qnt: "KRAKEN:QNTEUR",
  snx: "COINBASE:SNXEUR",
  yfi: "KRAKEN:YFIEUR",
  "1inch": "CRYPTO:1INCHUSD",
  ach: "CRYPTO:ACHUSD",
  aevo: "CRYPTO:AEVOUSD",
  akt: "CRYPTO:AKTUSD",
  ankr: "CRYPTO:ANKRUSD",
  api3: "CRYPTO:API3USD",
  astr: "CRYPTO:ASTRUSD",
  atlas: "CRYPTO:ATLASUSD",
  audio: "CRYPTO:AUDIOUSD",
  axs: "CRYPTO:AXSUSD",
  beam: "KRAKEN:BEAMEUR",
  bond: "CRYPTO:BONDUSD",
  bonk: "CRYPTO:BONKUSD",
  cfg: "CRYPTO:CFGUSD",
  chz: "CRYPTO:CHZUSD",
  dydx: "CRYPTO:DYDXUSD",
  egld: "CRYPTO:EGLDUSD",
  ena: "CRYPTO:ENAUSD",
  enj: "CRYPTO:ENJUSD",
  ens: "CRYPTO:ENSUSD",
  ethfi: "CRYPTO:ETHFIUSD",
  fet: "CRYPTO:FETUSD",
  fida: "CRYPTO:FIDAUSD",
  flr: "CRYPTO:FLRUSD",
  fxs: "CRYPTO:FXSUSD",
  gmx: "CRYPTO:GMXUSD",
  gno: "CRYPTO:GNOUSD",
  hft: "CRYPTO:HFTUSD",
  hnt: "CRYPTO:HNTUSD",
  inj: "CRYPTO:INJUSD",
  jasmy: "CRYPTO:JASMYUSD",
  jto: "CRYPTO:JTOUSD",
  jup: "CRYPTO:JUPUSD",
  kava: "CRYPTO:KAVAUSD",
  ldo: "CRYPTO:LDOUSD",
  lmwr: "CRYPTO:LMWRUSD",
  lpt: "CRYPTO:LPTUSD",
  mina: "CRYPTO:MINAUSD",
  mln: "CRYPTO:MLNUSD",
  movr: "CRYPTO:MOVRUSD",
  mpl: "CRYPTO:MPLUSD",
  nos: "CRYPTO:NOSUSD",
  ocean: "CRYPTO:OCEANUSD",
  omg: "CRYPTO:OMGUSD",
  ondo: "CRYPTO:ONDOUSD",
  op: "CRYPTO:OPUSD",
  orca: "CRYPTO:ORCAUSD",
  paxg: "CRYPTO:PAXGUSD",
  pepe: "CRYPTO:PEPEUSD",
  perp: "CRYPTO:PERPUSD",
  pyth: "CRYPTO:PYTHUSD",
  rari: "CRYPTO:RARIUSD",
  ray: "CRYPTO:RAYUSD",
  ren: "CRYPTO:RENUSD",
  rndr: "CRYPTO:RNDRUSD",
  rpl: "CRYPTO:RPLUSD",
  rune: "CRYPTO:RUNEUSD",
  sc: "CRYPTO:SCUSD",
  sei: "CRYPTO:SEIUSD",
  shib: "CRYPTO:SHIBUSD",
  strk: "KRAKEN:STRKEUR",
  stx: "CRYPTO:STXSUSD",
  super: "CRYPTO:SUPERUSD",
  sushi: "CRYPTO:SUSHIUSD",
  tia: "CRYPTO:TIA2USD",
  tnsr: "CRYPTO:TNSRUSD",
  tru: "CRYPTO:TRUUSD",
  w: "CRYPTO:WUSD",
  waves: "CRYPTO:WAVESUSD",
  wen: "KRAKEN:WENEUR",
  wif: "CRYPTO:WIFUSD",
  zeus: "KRAKEN:ZEUSUSD",
  zrx: "CRYPTO:ZRXUSD",
};

/**
 * THESE EXPORTS ARE USED FOR TRANSFERS SINCE WE ALLOW A DIFFERENT LIST OF ASSETS FOR TEST / DEV MODE
 */

export const isDevelopment =
  process.env.REACT_APP_WALLETS_API_URL?.includes("dev");

export const TEST_VALID_SECURITY_CODES: SecurityCode[] = [
  "eth",
  "xrp",
  "ltc",
  "usdc",
  "xlm",
];

export const transferrableAssetIdsToSecurityCodes: Record<
  string,
  SecurityCode
> = isDevelopment
  ? TEST_ASSET_IDS_TO_SECURITY_CODES
  : ASSET_ID_TO_SECURITY_CODE;

export const transferrableSecurityCodes = isDevelopment
  ? TEST_VALID_SECURITY_CODES
  : VALID_SECURITY_CODES;

export const transferrableSecurities = isDevelopment
  ? TEST_VALID_SECURITY_CODES
  : VALID_ASSET_IDS;

export const convertSecurityCodeSekToSecurityCode = (
  securityCodeSek: SecurityCodeSek
): SecurityCode => {
  const securityCode = securityCodeSek.replace("sek", "") as SecurityCode;
  if (VALID_SECURITY_CODES.includes(securityCode)) {
    return securityCode;
  }
  throw new Error(`Invalid SecurityCodeSek: ${securityCodeSek}`);
};

// FA security code
export const LIMIT_ORDER_ONLY_SECURITIES: Set<
  SecurityCode | SecurityCodeWithoutTransferSupport
> = new Set([
  "1inch",
  "ach",
  "aevo",
  "akt",
  "ankr",
  "api3",
  "astr",
  "atlas",
  "audio",
  "axs",
  "beam",
  "bond",
  "bonk",
  "cfg",
  "chz",
  "egld",
  "ena",
  "enj",
  "ens",
  "ethfi",
  "fet",
  "fida",
  "flr",
  "fxs",
  "gmx",
  "gno",
  "hft",
  "hnt",
  "inj",
  "jasmy",
  "jto",
  "jup",
  "kava",
  "ldo",
  "lmwr",
  "lpt",
  "mina",
  "mln",
  "movr",
  "mpl",
  "nos",
  "ocean",
  "omg",
  "ondo",
  "orca",
  "perp",
  "pyth",
  "rari",
  "ray",
  "rpl",
  "rune",
  "sc",
  "sei",
  "stx",
  "super",
  "tia",
  "tnsr",
  "tru",
  "w",
  "waves",
  "wen",
  "wif",
  "zeus",
  "zrx",
  "dydx",
  "op",
  "paxg",
  "pepe",
  "ren",
  "rndr",
  "shib",
  "strk",
  "sushi",
] as Array<SecurityCode | SecurityCodeWithoutTransferSupport>);
