import { MutableRefObject, useState } from "react";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useFinsharkDeposit } from "api/money/useFinsharkDeposit";
import { useGetBuyData } from "api/trading/useGetBuyData";
import { Input, Button } from "components";
import { isDevelopment, isMobileApp } from "config";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContactIdData } from "providers/ContactIdProvider";
import { openInBrowser } from "utils/mobile";
import { useDepositablePortfolioSelect } from "./useDepositablePortfolioSelect";
import { CashAccountSelect } from "../components/CashAccountSelect";
import { usePortfoliosAccountsState } from "../usePortfoliosAccountsState";

interface DepositModalProps {
  modalInitialFocusRef: MutableRefObject<null>;
  onClose: () => void;
}

interface ImportDepositQueryResponseFinshark {
  id: string;
  clientId: string;
  region: "SE" | "FI";
}

export const FinsharkDepositModalContent = ({
  onClose,
  modalInitialFocusRef,
}: DepositModalProps) => {
  const { t } = useModifiedTranslation();
  const { selectedContactId } = useGetContactIdData();
  // get data and representees
  const {
    data: {
      portfolios = [],
      juridical: { name: juridicalName } = { name: "" },
    } = { portfolios: [] },
  } = useGetContactInfo(false, selectedContactId);

  const portfolioSelectProps = useDepositablePortfolioSelect();
  const { portfolioId } = portfolioSelectProps;

  const { accountsLoading, ...cashAccountSelectProps } =
    usePortfoliosAccountsState(portfolioId);
  const {
    currentCashAccount: { availableBalance = 0, currency = "EUR" } = {},
  } = cashAccountSelectProps;

  const [amount, setAmount] = useState(0);

  const isAmountCorrect = !isNaN(availableBalance) && amount >= 0;

  const foundPortfolio = portfolios.find(
    (portfolio) => portfolio.id === portfolioId
  );
  const foundPortfolioShortName =
    (foundPortfolio ? foundPortfolio.shortName : portfolios[0].shortName) || "";
  const portfolioShortName = foundPortfolioShortName.toString() || "";

  const { handleTrade: handleDeposit, submitting } = useFinsharkDeposit({
    portfolioShortName,
    amount: amount.toString(),
    currency,
  });

  const startFinsharkApp = (response: ImportDepositQueryResponseFinshark) => {
    const clientId = response.clientId;

    // Passing this is needed for mobile. window.location.origin works for web app.
    const { REACT_APP_FINSHARK_REDIRECT_URI: redirectUriRaw = "" } =
      process.env;
    const useWindowUri = !isMobileApp && isDevelopment;
    const redirectUri = encodeURIComponent(
      useWindowUri ? window.location.origin : redirectUriRaw
    );

    // TODO change this to the correct region when SEK enabled
    const region = currency === "SEK" ? "SE" : "FI";

    const id = response.id;

    const sandBoxQueryParam =
      isDevelopment || window.location.href.includes("vercel.app")
        ? "sandbox=true&"
        : "";

    // Check if juridical form equals "Company" and conditionally add &accountType=business
    const accountTypeQueryParam =
      juridicalName === "Company" ? "&accountType=business" : "";

    const finsharkUrl =
      "https://flows.finshark.io?" +
      "type=payments&" +
      `clientId=${clientId}&` +
      sandBoxQueryParam +
      `redirectUri=${redirectUri}&` +
      `region=${region}&` +
      `paymentId=${id}&` +
      accountTypeQueryParam;

    openInBrowser(finsharkUrl);
  };

  const { data: { availableCash = 0 } = {}, refetch } = useGetBuyData(
    portfolioId.toString()
  );

  return (
    <div className="grid gap-2 min-w-[min(84vw,_375px)]">
      <CashAccountSelect
        {...cashAccountSelectProps}
        {...portfolioSelectProps}
        accountSelectLabel={t("moneyModal.toAccount")}
        availableCash={availableCash}
      />
      <hr className="mb-2" />
      <div className="flex flex-col gap-4 items-stretch ">
        <Input
          ref={modalInitialFocusRef}
          value={amount || ""}
          onChange={(event) => {
            setAmount(Number(event.currentTarget.value));
          }}
          label={t("moneyModal.depositAmountInputLabel", {
            currency: currency,
          })}
          type="number"
          error={
            !isAmountCorrect && !accountsLoading
              ? t("moneyModal.amountInputError")
              : undefined
          }
        />
        <Button
          disabled={amount === 0 || accountsLoading || !isAmountCorrect}
          isLoading={submitting}
          onClick={async () => {
            const response = await handleDeposit();
            if (response) {
              startFinsharkApp(response as ImportDepositQueryResponseFinshark);
              onClose();
              // Add a delay before refetching
              setTimeout(async () => {
                await refetch();
              }, 2000); // 2000 milliseconds = 2 seconds
            }
          }}
        >
          {t("moneyModal.depositButtonLabel")}
        </Button>
      </div>
      <hr className="my-1" />
      <div className="text-xs text-center text-gray-600 max-w-[375px]">
        {t("moneyModal.depositDisclaimer")}
      </div>
    </div>
  );
};
