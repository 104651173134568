import { useState } from "react";
import { keycloakService } from "../../services/keycloakService";

interface transactionSchema {
  portfolioShortName: string;
  securityCode: string;
  transactionTypeCode: string;
  currency: string;
  unitPrice: string;
  units?: number;
  contact: string;
  dryRun: boolean;
  orderType?: string;
  endTime?: number;
  unitAsset?: string;
}

export type BuyApiResponse = {
  extId: string;
  units: number;
  unitPrice: string; // Consider using a more specific number type like `number` or `BigDecimal` depending on the expected precision
  feePercentage: number;
  feeAmount: number;
};

export const useCryptoTrade = (newTradeOrder: transactionSchema) => {
  const [submitting, setSubmitting] = useState(false);
  const [response, setResponse] = useState<BuyApiResponse>();

  const handleTrade = async () => {
    try {
      setSubmitting(true);

      const token = await keycloakService.getToken();

      const response = await fetch(
        process.env.REACT_APP_TRANSACTIONS_API_URL_V2 || "",
        {
          method: "POST",
          body: JSON.stringify(newTradeOrder),
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();
      setResponse(result); // update response state

      setSubmitting(false);

      return result;
    } catch (error) {
      setSubmitting(false);

      throw error;
    }
  };
  return { handleTrade, submitting, response };
};
