import { useState, useEffect, useRef } from "react";

interface QuoteData {
  buy: string;
  sell: string;
}

export interface QuotesData {
  [key: string]: QuoteData;
}

// Talos -> FA name
const nameMapEur: { [key: string]: string[] } = {
  "BTC-EUR": ["Bitcoin"],
  "ETH-EUR": ["Ethereum", "Staked Ethereum"],
  "XRP-EUR": ["XRP"],
  "LTC-EUR": ["Litecoin"],
  "USDT-EUR": ["Tether"],
  "EOS-EUR": ["EOS"],
  "ADA-EUR": ["Cardano"],
  "AVAX-EUR": ["Avalanche"],
  "COMP-EUR": ["Compound"],
  "DAI-EUR": ["Dai"],
  "DOT-EUR": ["Polkadot"],
  "KSM-EUR": ["Kusama"],
  "LINK-EUR": ["Chainlink"],
  "MATIC-EUR": ["Polygon"],
  "SOL-EUR": ["Solana"],
  "UNI-EUR": ["Uniswap"],
  "USDC-EUR": ["USDC"],
  "XLM-EUR": ["Stellar"],
  "ATOM-EUR": ["Cosmos"],
  "NEAR-EUR": ["NEAR Protocol"],
  "TRX-EUR": ["TRON"],
  "AAVE-EUR": ["Aave"],
  "XTZ-EUR": ["Tezos"],
  "BCH-EUR": ["Bitcoin Cash"],
  "ETC-EUR": ["Ethereum Classic"],
  "DOGE-EUR": ["Dogecoin"],
  "GLMR-EUR": ["Moonbeam"],
  "XDC-EUR": ["XDC Network"],
  "ALGO-EUR": ["Algorand"],
  "APE-EUR": ["ApeCoin"],
  "FTM-EUR": ["Fantom"],
  "GALA-EUR": ["GALA"],
  "GRT-EUR": ["The Graph"],
  "MANA-EUR": ["Decentraland"],
  "SAND-EUR": ["The Sandbox"],
  "APT-EUR": ["Aptos"],
  "AR-EUR": ["Arweave"],
  "ARB-EUR": ["Arbitrum"],
  "BAL-EUR": ["Balancer"],
  "BAT-EUR": ["Basic Attention"],
  "BLUR-EUR": ["Blur"],
  "CRV-EUR": ["Curve DAO"],
  "FIL-EUR": ["Filecoin"],
  "FLOW-EUR": ["Flow"],
  "HBAR-EUR": ["Hedera"],
  "ICP-EUR": ["Internet Computer"],
  "IMX-EUR": ["Immutable"],
  "MKR-EUR": ["Maker"],
  "QNT-EUR": ["Quant"],
  "SNX-EUR": ["Synthetix Network"],
  "SUI-EUR": ["Sui"],
  "YFI-EUR": ["Yearn Finance"],
  "1INCH-EUR": ["1inch"],
  "ACH-EUR": ["Alchemy Pay"],
  "AEVO-EUR": ["Aevo"],
  "AKT-EUR": ["Akash Network"],
  "ANKR-EUR": ["Ankr Network"],
  "API3-EUR": ["API3"],
  "ASTR-EUR": ["Astar"],
  "ATLAS-EUR": ["Star Atlas"],
  "AUDIO-EUR": ["Audius"],
  "AXS-EUR": ["Axie Infinity"],
  "BEAM-EUR": ["Beam"],
  "BOND-EUR": ["BarnBridge"],
  "BONK-EUR": ["Bonk"],
  "CFG-EUR": ["Centrifuge"],
  "CHZ-EUR": ["Chiliz"],
  "DYDX-EUR": ["dYdX"],
  "EGLD-EUR": ["MultiversX"],
  "ENA-EUR": ["Ethena"],
  "ENJ-EUR": ["Enjin Coin"],
  "ENS-EUR": ["Ethereum Name Service"],
  "ETHFI-EUR": ["Ether.fi"],
  "FET-EUR": ["Fetch.ai"],
  "FIDA-EUR": ["Bonfida"],
  "FLR-EUR": ["Flare"],
  "FXS-EUR": ["Frax Share"],
  "GMX-EUR": ["GMX"],
  "GNO-EUR": ["Gnosis"],
  "HFT-EUR": ["Hashflow"],
  "HNT-EUR": ["Helium"],
  "INJ-EUR": ["Injective"],
  "JASMY-EUR": ["JasmyCoin"],
  "JTO-EUR": ["Jito"],
  "JUP-EUR": ["Jupiter"],
  "KAVA-EUR": ["Kava"],
  "LDO-EUR": ["Lido DAO"],
  "LMWR-EUR": ["LimeWire"],
  "LPT-EUR": ["Livepeer"],
  "MINA-EUR": ["Mina Protocol"],
  "MLN-EUR": ["Enzyme"],
  "MOVR-EUR": ["Moonriver"],
  "MPL-EUR": ["Maple"],
  "NOS-EUR": ["Nosana"],
  "OCEAN-EUR": ["Ocean Protocol"],
  "OMG-EUR": ["OMG Network"],
  "ONDO-EUR": ["Ondo"],
  "OP-EUR": ["Optimism"],
  "ORCA-EUR": ["Orca"],
  "PAXG-EUR": ["PAX Gold"],
  "PEPE-EUR": ["Pepe"],
  "PERP-EUR": ["Perpetual Protocol"],
  "PYTH-EUR": ["Pyth Network"],
  "RARI-EUR": ["RARI"],
  "RAY-EUR": ["Raydium"],
  "REN-EUR": ["Ren"],
  "RNDR-EUR": ["Render"],
  "RPL-EUR": ["Rocket Pool"],
  "RUNE-EUR": ["THORChain"],
  "SC-EUR": ["Siacoin"],
  "SEI-EUR": ["Sei"],
  "SHIB-EUR": ["Shiba Inu"],
  "STRK-EUR": ["Starknet"],
  "STX-EUR": ["Stacks"],
  "SUPER-EUR": ["SuperVerse"],
  "SUSHI-EUR": ["Sushi"],
  "TIA-EUR": ["Celestia"],
  "TNSR-EUR": ["Tensor"],
  "TRU-EUR": ["TrueFi"],
  "W-EUR": ["Wormhole"],
  "WAVES-EUR": ["Waves"],
  "WEN-EUR": ["Wen"],
  "WIF-EUR": ["dogwifhat"],
  "ZEUS-EUR": ["Zeus Network"],
  "ZRX-EUR": ["0x Protocol"],
};

const nameMapSek: { [key: string]: string[] } = {
  "BTC-SEK": ["Bitcoin"],
  "ETH-SEK": ["Ethereum"],
  "XRP-SEK": ["XRP"],
  "LTC-SEK": ["Litecoin"],
  "USDT-SEK": ["Tether"],
  "EOS-SEK": ["EOS"],
  "ADA-SEK": ["Cardano"],
  "AVAX-SEK": ["Avalanche"],
  "COMP-SEK": ["Compound"],
  "DAI-SEK": ["Dai"],
  "DOT-SEK": ["Polkadot"],
  "KSM-SEK": ["Kusama"],
  "LINK-SEK": ["Chainlink"],
  "MATIC-SEK": ["Polygon"],
  "SOL-SEK": ["Solana"],
  "UNI-SEK": ["Uniswap"],
  "USDC-SEK": ["USDC"],
  "XLM-SEK": ["Stellar"],
  "ATOM-SEK": ["Cosmos"],
  "NEAR-SEK": ["NEAR Protocol"],
  "TRX-SEK": ["TRON"],
  "AAVE-SEK": ["Aave"],
  "XTZ-SEK": ["Tezos"],
  "BCH-SEK": ["Bitcoin Cash"],
  "ETC-SEK": ["Ethereum Classic"],
  "DOGE-SEK": ["Dogecoin"],
  "GLMR-SEK": ["Moonbeam"],
  "XDC-SEK": ["XDC Network"],
  "ALGO-SEK": ["Algorand"],
  "APE-SEK": ["ApeCoin"],
  "FTM-SEK": ["Fantom"],
  "GALA-SEK": ["GALA"],
  "GRT-SEK": ["The Graph"],
  "MANA-SEK": ["Decentraland"],
  "SAND-SEK": ["The Sandbox"],
  "APT-SEK": ["Aptos"],
  "AR-SEK": ["Arweave"],
  "ARB-SEK": ["Arbitrum"],
  "BAL-SEK": ["Balancer"],
  "BAT-SEK": ["Basic Attention"],
  "BLUR-SEK": ["Blur"],
  "CRV-SEK": ["Curve DAO"],
  "FIL-SEK": ["Filecoin"],
  "FLOW-SEK": ["Flow"],
  "HBAR-SEK": ["Hedera"],
  "ICP-SEK": ["Internet Computer"],
  "IMX-SEK": ["Immutable"],
  "MKR-SEK": ["Maker"],
  "QNT-SEK": ["Quant"],
  "SNX-SEK": ["Synthetix Network"],
  "SUI-SEK": ["Sui"],
  "YFI-SEK": ["Yearn Finance"],
};

const useFetchQuotes = (currency: "EUR" | "SEK"): QuotesData => {
  const url = process.env.REACT_APP_QUOTES_API_URL || "";
  const [quotesData, setQuotesData] = useState<QuotesData>({});
  const intervalIdRef = useRef<number | NodeJS.Timeout>();

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        // Choose the appropriate name map based on the currency
        const nameMap = currency === "SEK" ? nameMapSek : nameMapEur;

        const response = await fetch(url as RequestInfo);
        const quotes = await response.json();
        const newRenamedPairs: QuotesData = {};
        for (const key in quotes) {
          if (nameMap[key]) {
            nameMap[key].forEach((alias) => {
              newRenamedPairs[alias] = quotes[key];
            });
          }
        }
        if (!isCancelled) {
          setQuotesData(newRenamedPairs);
        }
      } catch (error) {
        if (!isCancelled) {
          console.error(error);
        }
      }
    };

    fetchData();
    intervalIdRef.current = setInterval(fetchData, 3000);

    return () => {
      isCancelled = true;
      clearInterval(intervalIdRef.current as unknown as number);
    };
  }, [url, currency]);

  return quotesData;
};

export default useFetchQuotes;
