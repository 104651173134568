import { useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { StatusBar } from "@capacitor/status-bar";
import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";
import { FinsharkCallbackListener } from "components/MoneyModals/FinsharkCallbackHandler";
import { ZendeskChat } from "components/Zendesk/zendesk";
import { useTrackingScripts } from "hooks/useTrackingScripts";
import { APILanguageProvider } from "providers/APILanguageProvider/APILanguageProvider";
import { DetailProvider } from "providers/ContactIdProvider";
import { InitialLanguageProvider } from "providers/InitialLanguageProvider";
import { KeycloakProvider } from "providers/KeycloakProvider";
import { PersistedApolloProvider } from "providers/PersistedApolloProvider";
import { ServiceWorkerRegistrationProvider } from "providers/ServiceWorkerRegistrationProvider";
import { usePushNotifications } from "utils/mobile";
import { Toast } from "./components";
import { MaintenanceWrapper } from "./MaintenanceWrapper";
import { UserWithLinkedContactRoutes } from "./pages/userWithLinkedContact/routes";
import "react-toastify/dist/ReactToastify.css";
import "styles/fonts.css";

const initCapacitor = async () => {
  if (Capacitor.getPlatform() === "ios") {
    await StatusBar.hide();
  }
};

function App() {
  initCapacitor();
  useTrackingScripts();
  usePushNotifications();

  return (
    <InitialLanguageProvider>
      <ServiceWorkerRegistrationProvider>
        <ErrorBoundary>
          <KeycloakProvider>
            <PersistedApolloProvider>
              <DetailProvider>
                <APILanguageProvider>
                  <MaintenanceWrapper>
                    <FinsharkCallbackListener />
                    <UserWithLinkedContactRoutes />
                  </MaintenanceWrapper>
                </APILanguageProvider>
                <ZendeskChat />
              </DetailProvider>
            </PersistedApolloProvider>
            <Toast />
          </KeycloakProvider>
        </ErrorBoundary>
      </ServiceWorkerRegistrationProvider>
    </InitialLanguageProvider>
  );
}

export default App;
